import {
  ExpressFulFillmentsTypeEnum,
  Order,
  OrderItem,
  OrderSubItem,
  OrderSummaryItem,
  Sale,
} from 'redux/order-confirmation/types/types';
import { ItemType } from 'types/cart.types';
import { ProductType } from 'api/products/types';
import { RootState } from 'redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { isBlocked } from 'utils/status.comparer';
import { sale } from 'components/OrderConfirmation/translations/locale.constants';
import i18next from 'i18next';

export const orderConfirmationStatus = createSelector(
  ({ orderConfirmation }: RootState) => orderConfirmation.confirmation.status,
  (status) => status,
);

export const checkIsOrderConfirmation = createSelector(
  ({ orderConfirmation }: RootState) => orderConfirmation.confirmation.status,
  (status) => isBlocked(status),
);

export const selectSaleData = (state: RootState): Sale | undefined =>
  state.orderConfirmation.confirmation.data?.sale;

export const selectOrderData = (state: RootState): Order[] | undefined =>
  state.orderConfirmation.confirmation.data?.order;

export const selectOrderItemData = (
  state: RootState,
): OrderItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderItem;

export const selectOrderSubItemData = (
  state: RootState,
): OrderSubItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderSubItem?.filter(
    (element) => element.itemType !== ProductType.Package,
  );

export const selectOrderItemCount = (
  {
    orderConfirmation: {
      confirmation: { data },
    },
  }: RootState,
  orderId: number,
): number | undefined => {
  return data?.orderItem?.filter((item) => item.orderID === orderId).length;
};

export const selectOrderSubItemCount = (
  {
    orderConfirmation: {
      confirmation: { data },
    },
  }: RootState,
  orderId: number,
): number | undefined => {
  return data?.orderSubItem?.filter(
    (item) =>
      item.orderID === orderId &&
      (item.type === 4 || item.type === 1) &&
      item.itemType !== 2 &&
      item.itemType !== 3 &&
      !item.name.includes('CYO'),
  ).length;
};

export const selectFilterOrderItemData = (
  state: RootState,
  orderId: number,
): OrderItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderItem?.filter(
    (item) => item.orderID === orderId,
  );

export const selectFilterOrderSubItemData = (
  state: RootState,
  orderId: number,
): OrderSubItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderSubItem?.filter(
    (item) => item.orderID === orderId,
  );

export const lineItems = (
  {
    orderConfirmation: {
      confirmation: { data },
    },
  }: RootState,
  orderId: number,
): OrderSummaryItem[] => {
  const lineItemObj: {
    text: string;
    value: number;
    line: boolean;
    couponCodeStyle: boolean;
    orderType: number;
  }[] = [];
  if (data?.order) {
    data?.order.map((order) => {
      if (order.orderID === orderId) {
        lineItemObj.push({
          text: i18next.t(sale.SUBTOTAL),
          value: order.merchandiseTotal + order.merchandiseDiscount,
          line: false,
          couponCodeStyle: false,
          orderType: 0,
        });
        if (order.storeTax1 !== '' && order.orderTax1 > 0) {
          lineItemObj.push({
            text: `${order.storeTax1} (${order.storeTaxRate1}%)`,
            value: order.orderTax1,
            line: false,
            couponCodeStyle: false,
            orderType: 0,
          });
        }
        if (order.storeTax2 !== '' && order.orderTax2 > 0) {
          lineItemObj.push({
            text: `${order.storeTax2} (${order.storeTaxRate2}%)`,
            value: order.orderTax2,
            line: false,
            couponCodeStyle: false,
            orderType: 0,
          });
        }
        if (order.storeTax3 !== '' && order.orderTax3 > 0) {
          lineItemObj.push({
            text: `${order.storeTax3} (${order.storeTaxRate3}%)`,
            value: order.orderTax3,
            line: false,
            couponCodeStyle: false,
            orderType: 0,
          });
        }
        if (order.storeTax4 !== '' && order.orderTax4 > 0) {
          lineItemObj.push({
            text: `${order.storeTax4} (${order.storeTaxRate4}%)`,
            value: order.orderTax4,
            line: false,
            couponCodeStyle: false,
            orderType: 0,
          });
        }
        if (order.storeTax5 !== '' && order.orderTax5 > 0) {
          lineItemObj.push({
            text: `${order.storeTax5} (${order.storeTaxRate5}%)`,
            value: order.orderTax5,
            line: false,
            couponCodeStyle: false,
            orderType: 0,
          });
        }
        if (order.merchandiseDiscount !== 0 && order.couponCode !== '') {
          lineItemObj.push({
            text: i18next.t(sale.COUPON_CODE, { CouponCode: order.couponCode }),
            value: order.merchandiseDiscount,
            line: false,
            couponCodeStyle: true,
            orderType: 0,
          });
        }
        if (order.orderType === 1) {
          lineItemObj.push({
            text: i18next.t(sale.DELIVERY_FEE),
            value: order.deliveryOrShipmentCharge,
            line: false,
            couponCodeStyle: false,
            orderType: order.orderType,
          });
        }
        if (order.orderType === 3) {
          lineItemObj.push({
            text: i18next.t(sale.SHIPMENT_CHARGE),
            value: order.deliveryOrShipmentCharge,
            line: false,
            couponCodeStyle: false,
            orderType: order.orderType,
          });
        }
        if (order.surchargeName && order.surcharge > 0) {
          lineItemObj.push({
            text: `${order.surchargeName}`,
            value: order.surcharge,
            line: false,
            couponCodeStyle: false,
            orderType: order.orderType,
          });
        }
        lineItemObj.push({
          text: i18next.t(sale.TOTAL),
          value: order.orderTotal,
          line: true,
          couponCodeStyle: false,
          orderType: 0,
        });
      }
      return lineItemObj;
    });
  }
  return lineItemObj;
};

export const selectCYOAtOrderSubItemCount = (
  {
    orderConfirmation: {
      confirmation: { data },
    },
  }: RootState,
  orderItemId: number,
): number | undefined => {
  return data?.orderSubItem?.filter(
    (item) =>
      item.parentOrderItemID === orderItemId && item.name.includes('CYO'),
  ).length;
};

export const selectCYOAtOrderItemCount = (
  {
    orderConfirmation: {
      confirmation: { data },
    },
  }: RootState,
  orderItemId: number,
): number | undefined => {
  return data?.orderSubItem?.filter(
    (item) =>
      item.parentOrderItemID === orderItemId && !item.name.includes('CYO'),
  ).length;
};

export const isSpecialProductOrder = ({
  orderConfirmation: {
    confirmation: { data },
  },
}: RootState): boolean => {
  let containsSpecialProducts = false;
  data?.orderItem?.map((item) => {
    containsSpecialProducts = window.SPECIAL_PRODUCTS.includes(
      item.catalogNumber.toString(),
    );

    return containsSpecialProducts;
  });
  if (!containsSpecialProducts) {
    data?.orderSubItem?.map((item) => {
      containsSpecialProducts = window.SPECIAL_PRODUCTS.includes(
        item.catalogNumber.toString(),
      );

      return containsSpecialProducts;
    });
  }

  return containsSpecialProducts;
};

export const selectTotalProductCount = ({
  orderConfirmation: {
    confirmation: { data },
  },
}: RootState): number | undefined => {
  const totalArrangementCount =
    data?.orderItem?.reduce((acc, { quantity }) => acc + (quantity || 1), 0) ||
    0;
  const totalAddOnCount =
    data?.orderSubItem
      ?.filter(
        (product) =>
          (product.type === ProductType.AddOn ||
            product.type === ProductType.Arrangement) &&
          product.itemType !== ItemType.Package &&
          product.itemType !== ItemType.Upgrade &&
          !product.name.includes('CYO'),
      )
      .reduce((acc, { quantity }) => acc + (quantity || 1), 0) || 0;
  return totalArrangementCount + totalAddOnCount;
};

export const selectTotalProductCountForRecipient = (
  state: RootState,
  orderItemID: number,
): number | undefined => {
  const totalArrangementCount =
    state.orderConfirmation.confirmation.data?.orderItem
      ?.filter((item) => item.orderID === orderItemID)
      .reduce((acc, { quantity }) => acc + (quantity || 1), 0) || 0;
  const totalAddOnCount =
    state.orderConfirmation.confirmation.data?.orderSubItem
      ?.filter(
        (product) =>
          (product.type === ProductType.AddOn ||
            product.type === ProductType.Arrangement) &&
          product.itemType !== ItemType.Package &&
          product.itemType !== ItemType.Upgrade &&
          product.orderID === orderItemID &&
          !product.name.includes('CYO'),
      )
      .reduce((acc, { quantity }) => acc + (quantity || 1), 0) || 0;
  return totalArrangementCount + totalAddOnCount;
};

export const selectAllAddOns = (
  state: RootState,
  orderItemID: number,
): OrderSubItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderSubItem?.filter(
    (item) =>
      item.type === 4 &&
      item.itemType !== 2 &&
      item.parentOrderItemID === orderItemID &&
      !item.name.includes('CYO'),
  );

export const selectAllUpgrades = (
  state: RootState,
  orderItemID: number,
): OrderSubItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderSubItem?.filter(
    (item) =>
      item.itemType !== 2 &&
      item.type === 3 &&
      item.parentOrderItemID === orderItemID &&
      !item.name.includes('CYO'),
  );

export const selectAllPackage = (
  state: RootState,
  orderItemID: number,
): OrderSubItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderSubItem?.filter(
    (item) =>
      item.itemType === ItemType.Package &&
      item.parentOrderItemID === orderItemID,
  );

export const selectPackageItemCount = (
  {
    orderConfirmation: {
      confirmation: { data },
    },
  }: RootState,
  orderItemId: number,
): number | undefined => {
  return data?.orderSubItem?.filter(
    (item) => item.parentOrderItemID === orderItemId && item.itemType === 2,
  ).length;
};

export const selectOrderExpressFulfillmentsType = ({
  orderExpressFulfillments: {
    orderExpressFulfillments: { data },
  },
}: RootState): string | undefined => {
  let result = '';
  if (data && data.expressFulFillmentSelected) {
    if (data.expressFulfillmentType === ExpressFulFillmentsTypeEnum.Normal)
      result = 'normal delivery';
    if (
      data.expressFulfillmentType ===
      ExpressFulFillmentsTypeEnum.OneHourDelivery
    )
      result = 'one hour delivery';
    if (
      data.expressFulfillmentType ===
      ExpressFulFillmentsTypeEnum.TwoHourDelivery
    )
      result = 'two hour delivery';
  }
  return result;
};

export const selectAllFreeProducts = (
  state: RootState,
  orderItemID: number,
): OrderSubItem[] | undefined =>
  state.orderConfirmation.confirmation.data?.orderSubItem?.filter(
    (item) =>
      (item.itemType === ItemType.Arrangement ||
        item.type === ItemType.Arrangement) &&
      item.price === 0 &&
      !item.name.includes('CYO') &&
      item.parentOrderItemID === orderItemID,
  );
