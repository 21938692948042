export const AD_INQUIRY_TITLE = 'ad_inquiry_title';
export const ABOUT = 'about';
export const PRESS_ROOM = 'press_room';
export const BACK_LINK = 'back.link';
export const HOME_LINK = 'home.link';
export const PRESS_ROOM_LINK = 'press.room.link';
export const WHO_WE_ARE_LINK = 'who_we_are_link';
export const TIMELINE_LINK = 'timeline_link';
export const MEDIA_CONTACTS_TITLE = 'media_contacts_title';
export const MEDIA_CONTACT_INFO = 'media.contact.info';
export const NEWS_TITLE = 'news.title';
export const PRINT_WEB_TITLE = 'print_web_title';
export const REG_INQ_INFO = 'reg_inq_info';
export const MEDIA_EMAIL = 'media_email';
export const MEDIA_CONTACT_NUMBER = 'media_contact_number';
export const LOCAL_INQUIRY = 'local_inquiry';
export const CONTACT_STORE_LINK = 'contact_store_link';
export const FAQ_TITLE = 'frequently_asked_questions';
export const SPONSORSHIP = 'sponsorship';
export const DOWNLOAD_PRESS_KIT = 'download_press_kit';
export const LINK_ALT = 'link_alt';
export const TOP_ALT = 'top_alt';

export const WHO_WE_ARE_TITLE = 'who_we_are_title';
export const TIMELINE_TITLE = 'time_line_title';
export const WHO_WE_ARE_PRIDE = 'who_we_are_pride';
export const WHO_WE_ARE_AT_EDIBLE = 'who_we_are_at_edible';
export const TIMELINE_SUBTEXT = 'timeline_subtext';
export const NO_MATTER = 'who_we_Are_no_matter';
export const MADE_FRESH_DAILY_ALT = 'made_fresh_daily_alt';
export const GO_LOCAL_ALT = 'go_local';
export const ART_OF_WOW_ALT = 'art_of_wow';
export const EXTRA_SPECIAL_DELIVERY_ALT = 'extra_special_delivery';
export const CAREER_BANNER_HEADER = 'career_banner_header';
export const CAREER_BANNER_HEADER1 = 'career_banner_header1';
export const CAREER_BANNER_HEADER2 = 'career_banner_header2';
export const CAREER_BANNER_SUB_HEADER = 'career_banner_sub_header';
export const CAREER_BANNER_BUTTON_TEXT = 'career_banner_button_text';
export const CAREER_ABOUT = 'career_about';
export const CAREER_ABOUT_TEXT = 'career_about_text';
export const CAREER_CORPORATE_TEXT = 'career_corporate_text';
export const CAREER_OPENING_TEXT = 'career_opening_text';
export const CAREER_STORE_TEXT = 'career_store_text';
export const CAREER_FRUITS_TEXT = 'career_fruits_text';
export const CAREER_FRUITS_SUB_TEXT = 'career_fruits_sub_text';
export const CAREER_JOB_REVIEWS_FIRST = 'career.job.reviews.first';
export const CAREER_JOB_REVIEWS_NAME_FIRST = 'career.job.reviews.name.first';
export const CAREER_JOB_REVIEWS_DESIGNATION_FIRST =
  'career.job.reviews.designation.first';
export const CAREER_JOB_REVIEWS_SECOND = 'career.job.reviews.second';
export const CAREER_JOB_REVIEWS_NAME_SECOND = 'career.job.reviews.name.second';
export const CAREER_JOB_REVIEWS_DESIGNATION_SECOND =
  'career.job.reviews.designation.second';
export const LABOR_SUB_SECTION1_TITLE1 = 'labor_sub_section1_title1';
export const LABOR_SUB_SECTION1_TITLE2 = 'labor_sub_section1_title2';
export const LABOR_SUB_SECTION2_TITLE = 'labor_sub_section2_title';
export const LABOR_SUB_SECTION3_TITLE1 = 'labor_sub_section3_title1';
export const LABOR_SUB_SECTION3_TITLE2 = 'labor_sub_section3_title2';
export const LABOR_SUB_SECTION4_TITLE = 'labor_sub_section4_title';
export const LABOR_SUB_SECTION5_TITLE = 'labor_sub_section5_title';
export const LABOR_SUB_SECTION6_TITLE = 'labor_sub_section6_title';
export const LABOR_SUB_SECTION1_HOVER_TEXT = 'labor_sub_section1_hover_text';
export const LABOR_SUB_SECTION2_HOVER_TEXT = 'labor_sub_section2_hover_text';
export const LABOR_SUB_SECTION3_HOVER_TEXT = 'labor_sub_section3_hover_text';
export const LABOR_SUB_SECTION4_HOVER_TEXT = 'labor_sub_section4_hover_text';
export const LABOR_SUB_SECTION5_HOVER_TEXT = 'labor_sub_section5_hover_text';
export const LABOR_SUB_SECTION6_HOVER_TEXT = 'labor_sub_section6_hover_text';
export const FOLLOW_US_TEXT = 'follow_us_text';
export const CAREER_PROPERTIES_TITLE = 'career.properties.title';
export const CAREER_PROPERTIES_SUB_TITLE = 'career.properties.sub.title';
export const CAREER_PROPERTIES_ITEM_TITLE1 = 'career.properties.item.title1';
export const CAREER_PROPERTIES_ITEM_SLOGAN1 = 'career.properties.item.slogan1';
export const CAREER_PROPERTIES_ITEM_DESC1 = 'career.properties.item.desc1';
export const CAREER_PROPERTIES_ITEM_TITLE2 = 'career.properties.item.title2';
export const CAREER_PROPERTIES_ITEM_SLOGAN2 = 'career.properties.item.slogan2';
export const CAREER_PROPERTIES_ITEM_DESC2 = 'career.properties.item.desc2';
export const CAREER_PROPERTIES_ITEM_TITLE3 = 'career.properties.item.title3';
export const CAREER_PROPERTIES_ITEM_SLOGAN3 = 'career.properties.item.slogan3';
export const CAREER_PROPERTIES_ITEM_DESC3 = 'career.properties.item.desc3';
export const CAREER_PROPERTIES_ITEM_TITLE4 = 'career.properties.item.title4';
export const CAREER_PROPERTIES_ITEM_SLOGAN4 = 'career.properties.item.slogan4';
export const CAREER_PROPERTIES_ITEM_DESC4 = 'career.properties.item.desc4';
export const CAREER_PROPERTIES_ITEM_TITLE5 = 'career.properties.item.title5';
export const CAREER_PROPERTIES_ITEM_SLOGAN5 = 'career.properties.item.slogan5';
export const CAREER_PROPERTIES_ITEM_DESC5 = 'career.properties.item.desc5';
