import { SxProps } from '@mui/system';
import { ThemedSxProps } from 'types/mui/themed-sx-props.type';
import { colors } from 'utils/theme/colors';
import { fonts } from 'utils/theme/fonts';
import { theme } from 'utils/theme/theme';

export const mainContainer: SxProps = {
  width: ['320px', '688px', '1060px'],
  margin: '0 auto',
  mb: 6,
};
export const loginContainer: SxProps = {
  width: ['320px', '688px', '610px'],
  mb: [6, 6, 22],
};

export const forgotLink: SxProps = {
  mt: 3,
  textDecoration: 'underline',
  display: 'block',
  textAlign: 'left',
};

export const signinBtnStyle: SxProps = {
  width: ['320px', '280px', '280px'],
  mt: 6,
};

export const accountContainer: SxProps = {
  display: ['block', 'block', 'flex'],
  margin: ['0', '0', '0 auto'],
  width: ['360px', '688px', '1060px'],
  pl: [0, 5, 0],
  [theme.breakpoints.between(400, 435)]: {
    width: '100%',
  },
  [theme.breakpoints.between(820, 1100)]: {
    width: '95%',
    margin: '0 auto',
  },
  '& .MuiTabPanel-root': {
    padding: [2.5, 0, 0],
  },
};

export const containerStyle: SxProps = {
  width: '270px',
};

export const rewardContainerStyle: SxProps = {
  width: ['320px', '688px', '740px'],
  [theme.breakpoints.between(370, 395)]: {
    width: '340px',
  },
  [theme.breakpoints.between(400, 415)]: {
    width: '363px',
  },
  [theme.breakpoints.between(415, 435)]: {
    width: '380px',
  },
  [theme.breakpoints.between(820, 1100)]: {
    width: 'auto',
    maxWidth: '900px',
  },
};

export const messageContainer: SxProps = {
  backgroundColor: '#FFF9C4',
  height: ['64px', '44px', '44px'],
  padding: '12px 16px',
  mt: 8,
  mb: 4,
};
export const title: ThemedSxProps = () => ({
  fontSize: 20,
  color: colors.primaryTextColor,
  mt: 4,
  mb: 2,
  fontWeight: 600,
  letterSpacing: '0.15px',
  textTransform: 'uppercase',
  sup: {
    fontSize: 12,
  },
});
export const faqTitle: ThemedSxProps = () => ({
  color: colors.placeholderText,
  fontSize: 34,
  fontFamily: fonts.poppins,
  fontWeight: 500,
  lineHeight: '123.5%',
  letterSpacing: '0.25px',
});

export const accordionDetails: ThemedSxProps = () => ({
  fontWeight: 400,
  fontSize: 14,
  fontFamily: fonts.poppins,
  color: colors.helperText,
  lineHeight: '143%',
  fontStyle: 'normal',
  [theme.breakpoints.up('xs')]: {
    wordBreak: 'break-all',
  },
});

export const accordionTitle: ThemedSxProps = () => ({
  fontWeight: 500,
  fontSize: 16,
  fontFamily: fonts.poppins,
  color: colors.placeholderText,
  lineHeight: '150%',
  fontStyle: 'normal',
});

export const reminderContainerStyle: SxProps = {
  display: ['block', 'flex', 'flex'],
};

export const bannerImg: SxProps = {
  height: ['160px', '160px', '200px'],
  objectFit: 'cover',
  mt: 5,
  mb: 8,
};

export const selectStyle: SxProps = {
  width: '25%',
};
export const promoTextContents: SxProps = () => ({
  display: 'flex',
  flexDirection: 'column',
  width: ['100%', '80%', '89%'],
  paddingLeft: '16px',
  paddingBottom: '6px',
  '& .MuiTypography-root': {
    fontFamily: '',
    fontSize: '12px',
  },
});

export const rewardGrid: SxProps = () => ({
  display: 'flex',
  mt: 1,
  mb: 3,
});

export const historyRewardGrid: SxProps = () => ({
  opacity: '50%',
});

export const linkStyle: SxProps = {
  fontWeight: 500,
  mb: 4,
  textAlign: 'left',
  display: 'block',
  textTransform: 'capitalize',
  '&.Mui-selected': {
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: fonts.poppins,
    color: colors.primaryRed,
    lineHeight: '143%',
    fontStyle: 'normal',
  },
};

export const linkActiveStyle: SxProps = {
  fontWeight: 700,
  mb: 4,
  textAlign: 'left',
  display: 'block',
};

export const tabListStyle: SxProps = {
  '.MuiTabs-flexContainer': {
    display: ['ruby', 'ruby', 'flex'],
  },
  '.MuiTabs-scroller': {
    overflowX: 'auto',
  },
  display: 'flex',
  minWidth: '270px',
  padding: '8px 0px',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

export const baseInput: SxProps = {
  flex: '1 0 auto',
  borderColor: '#0000008F',
  width: ['183px', '548px', '600px'],
  [theme.breakpoints.between(400, 415)]: {
    width: '226px',
  },
  [theme.breakpoints.between(415, 435)]: {
    width: '244px',
  },
  [theme.breakpoints.between(820, 1100)]: {
    width: '760px',
  },
  height: '56px',
  mb: 2.5,
  '& input.MuiOutlinedInput-input': {
    padding: '17px 0px',
    '&::placeholder': {
      color: 'text.secondary',
      opacity: 1,
      fontWeight: 400,
    },
  },
};

export const searchBtnStyle: SxProps = {
  width: '120px',
  height: '56px',
  background: colors.placeholderText,
  ml: [2, 2.5, 2.5],
};

export const rewardContainer: SxProps = {
  flexGrow: 1,
  [theme.breakpoints.between(400, 435)]: {
    width: '410px',
  },
};

export const imgStyle: SxProps = {
  mt: [2, 0, 0],
};
