import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { ActionMenu } from 'components/Rewards/RewardLanding/MyRewards/Reminder/ActionMenu';
import { AddReminderDialog } from 'components/Rewards/RewardLanding/MyRewards/Reminder/AddReminderDialog';
import { Backdrop } from 'components/PDP/Details/components/PasControl/components/Backdrop/Backdrop';
import {
  CreateReminderInfoRequest,
  UpdateReminderInfoRequest,
} from 'api/rewards/types/create-reminder-request.type';
import { GiftRemindersResponse } from 'api/rewards/types/reminders-response.type';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { ReminderParams } from 'components/Rewards/RewardLanding/MyRewards/Reminder/types';
import { SearchReminder } from 'components/Rewards/RewardLanding/MyRewards/Reminder/SearchReminder';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { fetchOccasions } from 'components/PDP/Order/RecipientForm/components/Occasion/feature/actions';
import { format } from 'date-fns';
import { getOccasions } from 'components/PDP/Order/RecipientForm/components/Occasion/feature/selectors';
import { rewardsApiService } from 'api/rewards/rewards.api.service';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { sortOccasions } from 'components/PDP/Order/RecipientForm/components/Occasion/sorter';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const Reminders: FC = () => {
  const dispatch = useDispatch();
  const [openDialog, handleDisplay] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number>();
  const handleChange = (rowId: number) => {
    setExpandedRow(expandedRow === rowId ? -1 : rowId);
  };
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const [reminders, setReminders] = useState<GiftRemindersResponse[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(true);
  const [remindersLoaded, setRemindersLoaded] = useState<string>();
  const occasions = [...useSelector(getOccasions)].sort(sortOccasions);
  const [isAlertLoad, setIsAlertLoad] = useState<boolean>(false);
  const [alertTextMsg, setAlertTextMsg] = useState<string>();

  const displayHandler = (isOpen: boolean) => {
    handleDisplay(isOpen);
  };

  const addEditReminder = async (data: ReminderParams) => {
    if (Number(data.id) === 0) {
      const createReminderRequest: CreateReminderInfoRequest = {
        accountId: Number(data.accountId),
        title: data.title,
        occasionDate: data.occasionDate,
        occasionId: Number(data.occasionId),
        advanceNotificationDays: Number(data.advanceNotificationDays),
        recurring: data.recurring,
        note: data.note,
        deleted: false,
      };
      const res = await rewardsApiService.createReminderInfo(
        createReminderRequest,
      );
      setRemindersLoaded(res);
      setIsAlertLoad(true);
      setAlertTextMsg('added');
    } else {
      const updateReminderRequest: UpdateReminderInfoRequest = {
        id: Number(data.id),
        accountId: Number(data.accountId),
        title: data.title,
        occasionDate: data.occasionDate,
        occasionId: Number(data.occasionId),
        advanceNotificationDays: Number(data.advanceNotificationDays),
        recurring: data.recurring,
        note: data.note,
        deleted: false,
      };
      const res = await rewardsApiService.updateReminderInfo(
        updateReminderRequest,
      );
      setRemindersLoaded(res);
      setIsAlertLoad(true);
      setAlertTextMsg('updated');
    }
  };

  const deleteReminder = async (reminderId: number) => {
    const res = await rewardsApiService.deleteReminderInfo(reminderId);
    setRemindersLoaded(res);
    setDataLoaded(true);
    setIsAlertLoad(true);
    setAlertTextMsg('deleted');
  };

  const fetchReminders = async (accountId: number, searchText = '') => {
    const res = await rewardsApiService.getReminders(accountId, searchText);
    setReminders(res);
    setDataLoaded(false);
  };

  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  useEffect(() => {
    if (accountInfo.length && accountInfo[0].id)
      fetchReminders(accountInfo[0].id);
  }, [accountInfo, remindersLoaded]);

  const onSubmit = (searchText: string) => {
    fetchReminders(accountInfo[0].id, searchText);
  };

  useEffect(() => {
    if (occasions.length < 1) {
      dispatch(fetchOccasions());
    }
  }, []);

  useEffect(() => {
    if (isAlertLoad) {
      setTimeout(() => {
        setIsAlertLoad(false);
      }, 5000);
    }
  }, [isAlertLoad]);

  return (
    <>
      <Backdrop opened={dataLoaded} />
      <Box sx={styles.reminderContainerStyle} mt={4} mb={4}>
        <Typography variant="subtitle1" mb={2} mt={2} sx={styles.reminderStyle}>
          {i18next.t(TK.REWARD_GIFT_REMINDER)}
        </Typography>
        <Box
          display="grid"
          sx={styles.reminderBtnStyle}
          onClick={() => displayHandler(true)}
        >
          <Button variant="contained" type="submit">
            {i18next.t(TK.REWARD_REMINDER)}
          </Button>
        </Box>
      </Box>
      <SearchReminder onSubmitReminder={onSubmit} />
      {isAlertLoad && (
        <Alert sx={styles.alertContainer}>
          <Typography variant="body2" textAlign="center">
            {i18next.t(TK.REMINDER_DELETE_MSG, {
              AlertMsg: alertTextMsg,
            })}
          </Typography>
        </Alert>
      )}
      <Box>
        {(!reminders || reminders.length === 0) && (
          <Typography
            variant="subtitle2"
            textAlign="center"
            fontWeight="500"
            mb={2}
          >
            {i18next.t(TK.NO_REMINDER_MSG)}
          </Typography>
        )}
        {reminders.length > 0 && (
          <Box display="flex" sx={styles.reminderContainer}>
            <TableContainer component={Paper} sx={{ overflow: 'hidden' }}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <HideOnXs>
                    <TableRow
                      sx={{
                        display: 'flex',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableCell
                        sortDirection="asc"
                        sx={styles.reminderEventStyle}
                      >
                        <TableSortLabel direction="asc">
                          {i18next.t(TK.EVENT_DATE)}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ width: '19%' }}>
                        {i18next.t(TK.EVENT)}
                      </TableCell>
                      <TableCell sx={{ width: '15%' }}>
                        {i18next.t(TK.ACCOUNT_TITLE)}
                      </TableCell>
                      <TableCell sx={{ width: '19%' }}>
                        {i18next.t(TK.EVENT_ACTION)}
                      </TableCell>
                    </TableRow>
                  </HideOnXs>
                </TableHead>
                <TableBody>
                  <HideOnXs>
                    {reminders.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          display: 'flex',
                          borderBottom: '1px solid #c9c9c9',
                        }}
                      >
                        <Accordion
                          expanded={expandedRow === row.id}
                          onChange={() => handleChange(row.id)}
                          sx={styles.AccordionStyles}
                        >
                          <AccordionSummary>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box display="flex">
                                <TableCell sx={{ border: 'none' }}>
                                  {expandedRow === row.id ? '-' : '+'}{' '}
                                  {format(
                                    new Date(row.occasionDate),
                                    'MM/dd/yyyy',
                                  )}
                                </TableCell>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box>
                              <Box display="flex" pl={2}>
                                <Typography
                                  variant="subtitle3"
                                  component="p"
                                  width="160px"
                                  mt={2}
                                >
                                  {i18next.t(TK.REMINDER_TEXT)}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  component="p"
                                  width="140px"
                                  mt={2}
                                >
                                  {row.recurring
                                    ? 'every year - '
                                    : 'one time - '}
                                  {row.advanceNotificationDays}{' '}
                                  {i18next.t(TK.REMINDER_WEEK_TEXT)}
                                </Typography>
                              </Box>
                              <Box display="flex" pl={2}>
                                <Typography
                                  variant="subtitle3"
                                  component="p"
                                  width="160px"
                                  mt={2}
                                >
                                  {i18next.t(TK.REWARDS_TEXT)}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  component="p"
                                  width="515px"
                                  mt={2}
                                >
                                  {row.note}
                                </Typography>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <TableCell sx={styles.tableCellStyle}>
                          {row.name}
                        </TableCell>
                        <TableCell sx={styles.tableCellStyle}>
                          {row.title}
                        </TableCell>
                        <Box sx={styles.tableCellStyle}>
                          <ActionMenu
                            giftReminderDetails={row}
                            addEditReminder={addEditReminder}
                            deleteReminder={deleteReminder}
                            occasionCategories={occasions}
                          />
                        </Box>
                      </TableRow>
                    ))}
                  </HideOnXs>
                  <ShowUpXs>
                    {reminders.map((row) => (
                      <Box key={row.id} sx={styles.mobileContainer}>
                        <TableRow sx={styles.tableRowMobileStyle}>
                          <Box sx={styles.tableMobileStyle}>
                            <Accordion
                              expanded={expandedRow === row.id}
                              onChange={() => handleChange(row.id)}
                              sx={styles.AccordionStyles}
                            >
                              <AccordionSummary>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Box display="block">
                                    <TableCell
                                      sortDirection="asc"
                                      sx={{ width: '20%', display: 'ruby' }}
                                    >
                                      <TableSortLabel direction="asc">
                                        {expandedRow === row.id ? '-' : '+'}{' '}
                                        {i18next.t(TK.EVENT_DATE)}
                                      </TableSortLabel>
                                    </TableCell>
                                  </Box>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box
                                  mt={6}
                                  pl={2}
                                  sx={styles.reminderAccordianStyle}
                                >
                                  <Box display="flex">
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight="500"
                                      component="p"
                                      width="100px"
                                      mt={2}
                                    >
                                      {i18next.t(TK.REMINDER_TEXT)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      width="65%"
                                      mt={2}
                                      textAlign="right"
                                      sx={styles.reminderTextStyle}
                                    >
                                      {row.recurring
                                        ? 'every year - '
                                        : 'one time - '}
                                      {row.advanceNotificationDays}{' '}
                                      {i18next.t(TK.REMINDER_WEEK_TEXT)}
                                    </Typography>
                                  </Box>
                                  <Box display="contents">
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight="500"
                                      component="p"
                                      width="160px"
                                      mt={2}
                                    >
                                      {i18next.t(TK.REWARDS_TEXT)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      width="100%"
                                      mt={2}
                                    >
                                      {row.note}
                                    </Typography>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                            <TableCell
                              sx={{ width: '19%', paddingLeft: '18px' }}
                            >
                              {i18next.t(TK.EVENT)}
                            </TableCell>
                            <TableCell
                              sx={{ width: '17%', paddingLeft: '18px' }}
                            >
                              {i18next.t(TK.ACCOUNT_TITLE)}
                            </TableCell>
                          </Box>
                          <Box
                            sx={styles.tableMobileContentStyle}
                            height="100px"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ fontSize: '12px' }}
                            >
                              {format(new Date(row.occasionDate), 'MM/dd/yyyy')}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.title}</TableCell>
                          </Box>
                        </TableRow>
                        <Box
                          style={{
                            marginTop:
                              expandedRow === row.id ? '100px' : '10px',
                          }}
                        >
                          <ActionMenu
                            giftReminderDetails={row}
                            addEditReminder={addEditReminder}
                            deleteReminder={deleteReminder}
                            occasionCategories={occasions}
                          />
                        </Box>
                        <hr className="lineStyle" />
                      </Box>
                    ))}
                  </ShowUpXs>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <AddReminderDialog
        open={openDialog}
        onReject={() => displayHandler(false)}
        addEditReminder={addEditReminder}
        occasionCategories={occasions}
      />
    </>
  );
};
