import * as TK from 'components/Rewards/translations/locale.constants';

export const rewardCommonLocale = {
  [TK.REWARD_LOG_IN_FORM_EMAIL_LABEL]: 'Email Address*',
  [TK.REWARD_LOG_IN_FORM_PASSWORD_LABEL]: 'Password*',
  [TK.REWARD_LOG_IN_FORM_PASSWORD_PLACEHOLDER]: 'at least 8 characters',
  [TK.REWARD_LOG_IN_FORM_RESET_LABEL]: 'Forgot Your Password?',
  [TK.REWARD_LOG_IN_FORM_SIGNING_IN]: 'Signing In…',
  [TK.REWARD_LOG_IN_FORM_SUCCESS]: 'Success',
  [TK.REWARD_LOG_IN_FORM_SIGN_IN]: 'Sign In',
  [TK.REWARD_LOG_IN_FORM_MY_ACCOUNT]: 'my account',
  [TK.REWARD_LOG_IN_FORM_EMAIL_MESSAGE]:
    'Enter your email address and password to proceed.',
  [TK.REWARD_LOG_IN_FORM_REQUIRED_MESSAGE]: '* Required field',
  [TK.REWARD_DETAILED_MESSAGE]:
    'Edible Rewards add a delightful twist to incentives. Create memorable experiences for others while earning rewards for yourself.',
  [TK.REWARD_ORDER_MESSAGE]:
    'You have 5 more orders until your Free 6 count chocolate dipped fruit box',
  [TK.REMINDER_DELETE_MSG]: 'Gift reminder successfully {{AlertMsg}}.',
  [TK.ADDRESS_BOOK_DELETE_MSG]: 'Contact successfully {{AlertMsg}}.',
  [TK.MY_ACCOUNT]: 'my account',
  [TK.MY_REWARDS]: 'My rewards',
  [TK.ACCOUNT_PASSWORD]: 'Account & Password',
  [TK.ORDER_HISTORY]: 'order history',
  [TK.FAQ]: 'FAQs',
  [TK.EDIBLE_REWARDS]: 'edible rewards',
  [TK.REWARD_MESSAGE]: 'My Edible Rewards®',
  [TK.PROMO_MESSAGE]: '{{message}}',
  [TK.PROMO_EXPIRES]: 'Expires: ',
  [TK.PROMO_CODE]: 'Code: ',
  [TK.ACCOUNT_BUSINESS_NAME]: 'Business Name',
  [TK.REWARD_GIFT_REMINDER]: 'Gift Reminders',
  [TK.REWARD_MY_LOCATIONS]: 'My locations',
  [TK.REWARD_REMINDER]: 'Add A Reminder',
  [TK.NEW_CONTACT]: 'Add a new contact',
  [TK.NO_REMINDER_MSG]:
    'No reminders found. Add a reminder for a loved ones birthday, anniversaries, holidays, or just because gifts.',
  [TK.NO_ADDRESS_MSG]:
    'There are currently no contacts listed in your address book',
  [TK.NO_LOCATION_MSG]:
    'You currently have no locations associated with this account. Place an order to view suggested locations.',
  [TK.HOME_LINK]: 'Home',
  [TK.BACK_LINK]: 'Back',
  [TK.CLOSE]: 'Close',
  [TK.REWARDS_LINK]: 'Rewards',
  [TK.REWARDS_SIGNUP_LINK]: 'Signup',
  [TK.REWARDS_TOP_BANNER]: 'Rewards Top Banner',
  [TK.REWARDS_BOTTOM_BANNER]: 'Rewards Bottom Banner',
  [TK.GIFT_REMINDER]: 'gift reminder',
  [TK.ADDRESS_BOOK]: 'Address Book',
  [TK.ADDRESS_BOOK_GRID_ACTION]: 'Order/Edit/Delete',
  [TK.ADDRESS_BOOK_GRID_CITY_STATE]: 'City/State',
  [TK.ADDRESS_BOOK_GRID_NAME]: 'Name',
  [TK.PASSWORD_NOT_MATCHED_MSG]: 'Passwords do not match',
  [TK.CONFIRMED_PASSWORD_REQ_MSG]: 'Confirm Password is required',
  [TK.PASSWORD_REQ_MSG]: 'Password is required',
  [TK.OLD_PASSWORD_REQ_MSG]: 'Old Password is required',
  [TK.OLD_PASSWORD_INCORRECT_MSG]: 'Incorrect Old Password',
  [TK.PASSWORD_FAILED_MSG]: 'Oops! Something went wrong.',
  [TK.PASSWORD_CHANGED_SUCCESS_MSG]: 'Password changed successfully.',
  [TK.PASSWORD_HISTORY_VALIDATION_FAILURE]:
    'Previous 5 passwords cannot be reused!!!',
  [TK.CORRECT_PASSWORD_REQ_MSG]: 'Enter valid password',
  [TK.GIFT_REMINDER_DELETE]: 'Delete Gift Reminder',
  [TK.GIFT_ADDRESS_BOOK_DELETE]: 'Delete Address Book',
  [TK.DELETE_CONFIRMATION_MSG]: 'Are you sure you want to delete this item?',
  [TK.CHOOSE_OPTION]: 'Please Choose',
  [TK.REMINDER_TITLE]: 'Reminder Title*',
  [TK.REMINDER_OCCASION]: 'Occasion*',
  [TK.REMINDER_OCCASION_ERROR]: 'Choose your occasion',
  [TK.REMINDER_OCCASION_DATE]: 'Occasion Date*',
  [TK.REMINDER_OCCASION_DATE_ERROR]: 'Choose reminder date',
  [TK.REMINDER_REMIND_ME]: 'Remind Me*',
  [TK.REMINDER_REMIND_ME_ERROR]: 'Choose a Remind Me*',
  [TK.REMINDER_OCCASION_DATE_ERROR]: 'Choose reminder period',
  [TK.REMINDER_TITLE_MSG]: 'Enter a reminder title',
  [TK.REMINDER_TITLE_SUB_MSG]: "Ex: Kaitlin's Birthday",
  [TK.REMINDER_NOTE_ERROR_TEXT]: 'A note is required',
  [TK.REWARD_DIALOG_SAVE]: 'Save',
  [TK.REWARD_DIALOG_OK]: 'Ok',
  [TK.REMINDER_COMMMENT]: 'Comments/Notes',
  [TK.REMINDER_NOTES]:
    'Leave any notes for yourself that you feel would be helpful.',
  [TK.REMINDER_TEXT]: 'Reminder',
  [TK.REMINDER_WEEK_TEXT]: 'day prior',
  [TK.REWARDS_TEXT]: 'Comments/Notes',
  [TK.ACCOUNT_PASSWORD_TITLE]: 'my edible rewards account',
  [TK.ACCOUNT_SAVE]: 'Save',
  [TK.CANCEL_TEXT]: 'Cancel',
  [TK.ACCOUNT_PASSWORD_SUBTITLE]: 'My contact information',
  [TK.PASSWORD_TITLE]: 'change account password',
  [TK.REMINDER_SEARCH_TEXT]: 'Search by event',
  [TK.ADDRESS_SEARCH_TEXT]: 'Search by name',
  [TK.SEARCH_TEXT]: 'Search',
  [TK.ORDER_BUTTON_TEXT]: 'Place A New Order',
  [TK.NO_ORDERS_FOUND]: 'No orders found',
  [TK.PASSWORD_REQUIREMENTS_MESSAGE]:
    'Your password must be at least 8 characters, and must contain at least 1 special character (!@#$%^&*).',
  [TK.ORDER_TEXT]:
    'The table below displays all orders placed on <a href="https://www.edible.com"  style="text-decoration: underline;" target="_blank">www.edible.com</a> while signed into your Edible Rewards® account. It doesn’t display orders placed in store, through our customer care center, or orders placed online without signing into your account.',
  [TK.CONTACT_DIALOG_TITLE]: 'Contact Information',
  [TK.SIGNUP_FIRST_NAME]: 'First Name',
  [TK.SIGNUP_LAST_NAME]: 'Last Name',
  [TK.PHONE_NUMBER]: 'Phone Number',
  [TK.PHONE]: 'Phone',
  [TK.SIGNUP_BIRTHDAY]: 'Birthday',
  [TK.EMAIL_ADDRESS]: 'Email Address',
  [TK.EMAIL_TEXT]: 'Email',
  [TK.ACTION_ORDER]: 'Order',
  [TK.ACTION_EDIT]: 'Edit',
  [TK.ACTION_DELETE]: 'Delete',
  [TK.ACTION_REMOVE]: 'Remove',
  [TK.ACTION_TEXT]: 'Actions',
  [TK.OLD_PASSWORD]: 'Old Password',
  [TK.NEW_PASSWORD]: 'New Password*',
  [TK.CONFIRM_PASSWORD]: 'Confirm Password',
  [TK.ACCOUNT_TITLE]: 'Title',
  [TK.ACCOUNT_MY_ADDRESS]: 'My address',
  [TK.ACCOUNT_ADDRESS_TYPE]: 'Address Type',
  [TK.ACCOUNT_STREET_ADDRESS]: 'Street Address',
  [TK.ACCOUNT_APARTMENT]: 'Apt/Floor/Suite',
  [TK.ACCOUNT_COUNTRY]: 'Country',
  [TK.ACCOUNT_CITY]: 'City/Town',
  [TK.ACCOUNT_STATE]: 'State/Province',
  [TK.ACCOUNT_ZIP]: 'Zip code',
  [TK.ACCOUNT_CHANGE_PASSWORD]: 'Change password',
  [TK.ACCOUNT_ADDRESS]: 'Address',
  [TK.OTHERS_TEXT]: 'Others',
  [TK.EVENT_DATE]: 'Event Date',
  [TK.EVENT]: 'Event',
  [TK.EVENT_ACTION]: 'Order/Edit/Delete',
  [TK.PRIOR_1_DAY]: '1 day prior',
  [TK.PRIOR_2_DAYS]: '2 days prior',
  [TK.PRIOR_5_DAYS]: '5 days prior',
  [TK.PRIOR_8_DAYS]: '8 days prior',
  [TK.PRIOR_10_DAYS]: '10 days prior',
  [TK.PRIOR_15_DAYS]: '15 days prior',
  [TK.TRACK_TEXT]: 'Track',
  [TK.VIEW_TEXT]: 'View',
  [TK.ORDER_TYPE]: 'Order Type/Date',
  [TK.ORDER_NUMBER]: 'Order #',
  [TK.RECIPIENT_NAME]: 'Recipient/Pickup Name',
  [TK.FULFILLMENT_DATE]: 'Fulfillment Date',
  [TK.ORDER_TOTAL]: 'Total',
  [TK.ORDER_ACTIONS]: 'Track/View/Cancel',
  [TK.ORDERS_UNTIL]: 'until your',
  [TK.FREE_TXT]: 'FREE',
  [TK.TITLE_TEXT1]: 'Dr.',
  [TK.TITLE_TEXT2]: 'Mr.',
  [TK.TITLE_TEXT3]: 'Mrs.',
  [TK.TITLE_TEXT4]: 'Ms.',
  [TK.TITLE_TEXT5]: 'Prof.',
};
