import { ApiBaseService } from 'api/apibase.service';
import { PressRoomResponse, TimeLineResponse } from 'api/about/type/about';

class AboutApiService extends ApiBaseService {
  getPressRoomInfo(
    fromDate: string,
    toDate: string,
  ): Promise<PressRoomResponse[]> {
    return this.apibase
      .get<PressRoomResponse[]>(`/about/news/${fromDate}/${toDate}`)
      .then(({ data }) => data);
  }

  getTimeLineInfo(): Promise<TimeLineResponse[]> {
    return this.apibase
      .get<TimeLineResponse[]>(`/about/timeline`)
      .then(({ data }) => data);
  }
}

export const aboutApiService = new AboutApiService();
