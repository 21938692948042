import {
  BUSINESS_GIFTING_FILTER_ALIAS,
  BUSINESS_GIFTS_FILTER_ALIAS,
  CAREERS,
  CUSTOMER_SERVICE_ALIAS,
  LOCAL_LEGAL_ALIAS,
  PRESS,
  STORE_COMING_SOON_PATH,
  WHO_WE_ARE,
} from 'containers/MainContainer/constants/path';
import { ORDER_CONFIRMATION_PATH } from 'containers/OrderConfirmationContainer/constants';
import { isInProduction } from 'utils/environment';

export const getPdpPath = (
  productPath: string,
  queryParams?: Record<string, string>,
): string => {
  const path = `/fruit-gifts/${productPath}`;

  if (queryParams) {
    return `${path}?${new URLSearchParams(queryParams).toString()}`;
  }

  return path;
};

export const getHomePaths = (): string[] => ['/home', '/'];

export const getOrderConfirmationPaths = (): string[] => [
  ORDER_CONFIRMATION_PATH,
  `${ORDER_CONFIRMATION_PATH}.aspx`,
];
export const getStoreComingSoonPaths = (): string[] => [
  STORE_COMING_SOON_PATH,
  `${STORE_COMING_SOON_PATH}.aspx`,
];

export const getPressRoomPaths = (): string[] => [PRESS, `${PRESS}.aspx`];
export const getWhoWeArePaths = (): string[] => [
  WHO_WE_ARE,
  `${WHO_WE_ARE}.aspx`,
];
export const getCareerPaths = (): string[] => [CAREERS, `${CAREERS}.aspx`];
export const getTrackOrderEndpoints = (): string[] => [
  `/${CUSTOMER_SERVICE_ALIAS}/track-your-order/:orderNumber`,
  `/${LOCAL_LEGAL_ALIAS}/track-your-order`,
];

export const isHomePath = (path: string): boolean =>
  getHomePaths().includes(path);

export const getBusinessGiftingPaths = (): string[] => {
  const paths = [BUSINESS_GIFTING_FILTER_ALIAS, BUSINESS_GIFTS_FILTER_ALIAS];
  return (isInProduction() ? paths : paths.concat('new-baby-gifts')).map(
    (alias) => `/${alias}`,
  );
};
