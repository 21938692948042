import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { AddContactDialog } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/AddContactDialog';
import { AddressBookParams } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/types';
import { AddressBookResponse } from 'api/rewards/types/address-book-response.type';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { ConfirmationDialog } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/ConfirmationDialog';
import { Key } from 'enums/key';
import { Links } from 'enums/common-links';
import { navigate } from 'utils/navigate';
import { rewardsApiService } from 'api/rewards/rewards.api.service';
import { useMenu } from 'hooks/useMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

interface Props {
  address: AddressBookResponse;
  addEditAddress: (data: AddressBookParams) => void;
  deleteAddress: (addressId: number) => void;
}
export const AddressBookActionMenu: FC<Props> = ({
  address,
  addEditAddress,
  deleteAddress,
}) => {
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    document.body.style.overflow = 'scroll';
    setExpanded(open);
  }, [open]);
  const handleCloseAndUpdate = () => {
    handleClose();
  };

  const handleAccordionToggle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (anchorEl) {
      handleCloseAndUpdate();
    } else {
      handleClick(event);
    }
  };
  const [openConfirmationDialog, handleConfirmationDisplay] =
    useState<boolean>(false);
  const [openDialog, handleDisplay] = useState<boolean>(false);

  const redirectOrderToFruitArrangements = async () => {
    const res = await rewardsApiService.getAddressBookById(address.id);
    if (res) {
      sessionStorage.setItem('userContactInfo', JSON.stringify(res));
      navigate(Links.FruitArrangements);
    }
  };

  const openConfirmationRemoveDialog = () => {
    handleConfirmationDisplay(true);
    handleClose();
  };

  const handleConfirmationClosePopup = () => {
    handleConfirmationDisplay(false);
  };

  const openContactDialog = () => {
    handleDisplay(true);
    handleClose();
  };

  const handleClosePopup = () => {
    handleDisplay(false);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    }
    if (e.key === Key.Escape) {
      handleClose();
    }
  };
  return (
    <Box mr={2}>
      <Button
        data-test="address-button"
        color="secondary"
        id="address-menu-button"
        aria-controls={open ? 'address-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleAccordionToggle}
        endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={styles.actionColor}
      >
        <Typography sx={styles.actionLink}>
          {i18next.t(TK.ACTION_TEXT)}
        </Typography>
      </Button>
      <Menu
        data-test="address-div"
        id="address-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'address-menu-button',
        }}
      >
        <MenuItem
          onClick={redirectOrderToFruitArrangements}
          component="a"
          tabIndex={0}
          onKeyDown={onKeyDown}
        >
          <Typography variant="body2">{i18next.t(TK.ACTION_ORDER)}</Typography>
        </MenuItem>
        <MenuItem
          onClick={openContactDialog}
          component="a"
          tabIndex={0}
          onKeyDown={onKeyDown}
        >
          <Typography variant="body2">{i18next.t(TK.ACTION_EDIT)}</Typography>
        </MenuItem>
        <MenuItem
          onClick={openConfirmationRemoveDialog}
          component="a"
          tabIndex={0}
          onKeyDown={onKeyDown}
        >
          <Typography variant="body2">
            {' '}
            {i18next.t(TK.ACTION_DELETE)}
          </Typography>
        </MenuItem>
      </Menu>
      <AddContactDialog
        open={openDialog}
        onReject={handleClosePopup}
        address={address}
        addEditAddress={addEditAddress}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        onReject={handleConfirmationClosePopup}
        address={address}
        deleteAddress={deleteAddress}
      />
    </Box>
  );
};
