import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { AccountInfo } from 'providers/Session/feature/user.types';
import { AddContactDialog } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/AddContactDialog';
import { AddressBookActionMenu } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/ActionMenu';
import { AddressBookParams } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/types';
import { AddressBookResponse } from 'api/rewards/types/address-book-response.type';
import {
  CreateAddressBookInfoRequest,
  UpdateAddressBookInfoRequest,
} from 'api/rewards/types/address-book-request.type';
import { HideOnXs } from 'utils/hidden/hide-on-xs';
import { SearchBookAddress } from 'components/Rewards/RewardLanding/MyRewards/AddressBook/SearchAddressBook';
import { ShowUpXs } from 'utils/hidden/show-up-xs';
import { rewardsApiService } from 'api/rewards/rewards.api.service';
import { selectUserAccountInformation } from 'providers/Session/feature/user.selectors';
import { useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

export const AddressBook: FC = () => {
  const [openContatcDialog, handleContactDisplay] = useState<boolean>(false);
  const [expandedRow, setExpandedRow] = useState<number>();
  const userAccountInformation = useSelector(selectUserAccountInformation);
  const [address, setAddress] = useState<AddressBookResponse[]>([]);
  const [addressBookLoaded, setAddressBookLoaded] = useState<string>();
  const [isAlertLoad, setIsAlertLoad] = useState<boolean>(false);
  const [alertTextMsg, setAlertTextMsg] = useState<string>();

  const openContactDialog = () => {
    handleContactDisplay(true);
  };
  const handleContactClose = () => {
    handleContactDisplay(false);
  };

  const getCountryId = (countryCode: string): number => {
    switch (countryCode) {
      case 'US':
        return 1;
      case 'CA':
        return 2;
      default:
        return 0;
    }
  };

  const addEditAddress = async (data: AddressBookParams) => {
    const countryId = getCountryId(data.country);
    if (Number(data.id) === 0) {
      const createAddressBookRequest: CreateAddressBookInfoRequest = {
        accountId: Number(data.accountId),
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        addressType: data.addressType,
        address1: data.address1,
        address2: data.address2,
        areaOther: data.zipCode,
        cityId: data.cityId,
        cityMLId: Number(data.city),
        cityOther: data.cityOther,
        stateId: Number(data.state),
        stateOther: data.stateOther,
        countryId,
        homePhone: data.phone ?? '',
        email: data.email,
        company: data.company ?? '',
      };
      const res = await rewardsApiService.createAddressBookInfo(
        createAddressBookRequest,
      );
      setAddressBookLoaded(res);
      setIsAlertLoad(true);
      setAlertTextMsg('added');
    } else {
      const updateAddressBookRequest: UpdateAddressBookInfoRequest = {
        id: Number(data.id),
        accountId: Number(data.accountId),
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        addressType: data.addressType ?? 1,
        address1: data.address1,
        address2: data.address2,
        areaOther: data.zipCode,
        cityId: data.cityId,
        cityMLId: Number(data.city),
        cityOther: data.cityOther,
        stateId: Number(data.state),
        stateOther: data.stateOther,
        countryId,
        homePhone: data.phone ?? '',
        email: data.email,
        company: data.company ?? '',
      };
      const res = await rewardsApiService.updateAddressBookInfo(
        updateAddressBookRequest,
      );
      setAddressBookLoaded(res);
      setIsAlertLoad(true);
      setAlertTextMsg('updated');
    }
  };

  const deleteAddress = async (addressId: number) => {
    const res = await rewardsApiService.deleteAddressBookInfo(addressId);
    setAddressBookLoaded(res);
    setIsAlertLoad(true);
    setAlertTextMsg('deleted');
  };

  const fetchAddress = async (accountId: number, searchText = '') => {
    const res = await rewardsApiService.getAddressBook(accountId, searchText);
    setAddress(res);
  };

  const accountInfo: AccountInfo[] = userAccountInformation?.accountInfo ?? [];
  useEffect(() => {
    if (accountInfo.length && accountInfo[0].id)
      fetchAddress(accountInfo[0].id);
  }, [accountInfo, addressBookLoaded]);

  const handleChange = (rowId: number) => {
    setExpandedRow(expandedRow === rowId ? -1 : rowId);
  };

  const onSubmit = (searchText: string) => {
    fetchAddress(accountInfo[0].id, searchText);
  };

  useEffect(() => {
    if (isAlertLoad) {
      setTimeout(() => {
        setIsAlertLoad(false);
      }, 5000);
    }
  }, [isAlertLoad]);

  return (
    <>
      <Box sx={styles.reminderContainerStyle} mt={4} mb={4}>
        <Typography
          variant="subtitle1"
          mb={2}
          mt={2}
          sx={styles.addressBookStyle}
        >
          {i18next.t(TK.ADDRESS_BOOK)}
        </Typography>
        <Box display="grid" sx={styles.newContactBtnStyle}>
          <Button variant="contained" type="submit" onClick={openContactDialog}>
            {i18next.t(TK.NEW_CONTACT)}
          </Button>
        </Box>
      </Box>
      <SearchBookAddress onSubmitAddressBook={onSubmit} />
      {isAlertLoad && (
        <Alert sx={styles.alertContainer}>
          <Typography variant="body2" textAlign="center">
            {i18next.t(TK.ADDRESS_BOOK_DELETE_MSG, {
              AlertMsg: alertTextMsg,
            })}
          </Typography>
        </Alert>
      )}
      <Box display="flex">
        {(!address || address.length === 0) && (
          <Typography
            variant="subtitle2"
            textAlign="center"
            fontWeight="500"
            mb={2}
          >
            {i18next.t(TK.NO_ADDRESS_MSG)}
          </Typography>
        )}
        {address.length > 0 && (
          <Box display="flex" sx={styles.addressBookContainer}>
            <TableContainer component={Paper} sx={{ overflow: 'hidden' }}>
              <Table
                sx={{ minWidth: [320, 650, 650], marginBottom: '40px' }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <HideOnXs>
                    <TableRow
                      sx={{
                        display: 'flex',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      }}
                    >
                      <TableCell
                        sortDirection="asc"
                        sx={styles.addressHeadStyle}
                      >
                        <TableSortLabel direction="asc">
                          {i18next.t(TK.ADDRESS_BOOK_GRID_NAME)}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={styles.addressHeadStyle}>
                        {i18next.t(TK.ADDRESS_BOOK_GRID_CITY_STATE)}
                      </TableCell>
                      <TableCell sx={styles.addressHeadStyle}>
                        {i18next.t(TK.ADDRESS_BOOK_GRID_ACTION)}
                      </TableCell>
                    </TableRow>
                  </HideOnXs>
                </TableHead>
                <TableBody>
                  <HideOnXs>
                    {address.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          display: 'flex',
                          borderBottom: '1px solid #c9c9c9',
                        }}
                      >
                        <Accordion
                          expanded={expandedRow === row.id}
                          onChange={() => handleChange(row.id)}
                          sx={styles.AccordionStyles}
                        >
                          <AccordionSummary>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Box display="flex">
                                <TableCell sx={{ border: 'none' }}>
                                  {expandedRow === row.id ? '-' : '+'}{' '}
                                  {row.displayName}
                                </TableCell>
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box>
                              <Box display="flex" pl={2}>
                                <Typography
                                  variant="subtitle3"
                                  component="p"
                                  width="100px"
                                  fontWeight="500"
                                  mt={2}
                                >
                                  {i18next.t(TK.PHONE_NUMBER)}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  component="p"
                                  width="140px"
                                  mt={2}
                                >
                                  {row.cellPhone ||
                                    row.homePhone ||
                                    row.workPhone ||
                                    'N/A'}
                                </Typography>
                                <Typography
                                  variant="subtitle3"
                                  component="p"
                                  width="100px"
                                  fontWeight="500"
                                  mt={2}
                                >
                                  {i18next.t(TK.EMAIL_ADDRESS)}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  component="p"
                                  width="515px"
                                  mt={2}
                                >
                                  {row.email}
                                </Typography>
                              </Box>
                              <Box display="flex" pl={2} mb={1}>
                                <Typography
                                  variant="subtitle3"
                                  component="p"
                                  width="160px"
                                  fontWeight="500"
                                  mt={2}
                                >
                                  {i18next.t(TK.ACCOUNT_ADDRESS)}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  component="p"
                                  width="515px"
                                  mt={2}
                                >
                                  {row.address1} {row.address2} {row.city},{' '}
                                  {row.stateCode}
                                </Typography>
                              </Box>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                        <TableCell sx={styles.cityStyle}>
                          {row.city}, {row.stateCode}
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '20%',
                            border: 'none',
                            textAlign: 'center',
                          }}
                        >
                          <AddressBookActionMenu
                            address={row}
                            addEditAddress={addEditAddress}
                            deleteAddress={deleteAddress}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </HideOnXs>
                  <ShowUpXs>
                    {address.map((row) => (
                      <Box key={row.id} sx={styles.mobileContainer}>
                        <TableRow key={row.id} sx={styles.tableRowMobileStyle}>
                          <Box sx={styles.tableMobileStyle}>
                            <Accordion
                              expanded={expandedRow === row.id}
                              onChange={() => handleChange(row.id)}
                              sx={styles.AccordionStyles}
                            >
                              <AccordionSummary>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Box display="block">
                                    <TableCell
                                      sortDirection="asc"
                                      sx={{ width: '20%', display: 'ruby' }}
                                    >
                                      <TableSortLabel direction="asc">
                                        {expandedRow === row.id ? '-' : '+'}{' '}
                                        {i18next.t(TK.ADDRESS_BOOK_GRID_NAME)}
                                      </TableSortLabel>
                                    </TableCell>
                                  </Box>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box mt={2} pl={2}>
                                  <Box
                                    display="flex"
                                    sx={styles.addressAccordianStyle}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      component="p"
                                      width="185px"
                                      fontWeight="500"
                                      mt={2}
                                    >
                                      {i18next.t(TK.PHONE)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      mt={2}
                                      textAlign="right"
                                      sx={styles.accordianPhoneStyle}
                                    >
                                      {row.cellPhone ||
                                        row.homePhone ||
                                        row.workPhone ||
                                        'N/A'}
                                    </Typography>
                                  </Box>
                                  <Box
                                    display="flex"
                                    sx={styles.addressAccordianStyle}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      component="p"
                                      width="20%"
                                      fontWeight="500"
                                      mt={2}
                                    >
                                      {i18next.t(TK.EMAIL_TEXT)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      textAlign="right"
                                      mt={2}
                                      sx={styles.accordianEmailStyle}
                                    >
                                      {row.email}
                                    </Typography>
                                  </Box>
                                  <Box
                                    display="flex"
                                    mb={1}
                                    sx={styles.addressAccordianStyle}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      component="p"
                                      width="120px"
                                      fontWeight="500"
                                      mt={2}
                                    >
                                      {i18next.t(TK.ACCOUNT_ADDRESS)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      component="p"
                                      mt={2}
                                      textAlign="right"
                                      sx={styles.accordianAddressStyle}
                                    >
                                      {row.address1} {row.address2} {row.city},{' '}
                                      {row.stateCode}
                                    </Typography>
                                  </Box>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                            <TableCell sx={{ paddingLeft: '16px' }}>
                              {i18next.t(TK.ADDRESS_BOOK_GRID_CITY_STATE)}
                            </TableCell>
                          </Box>
                          <Box
                            sx={styles.tableMobileContentStyle}
                            height="50px"
                          >
                            <TableCell sx={{ border: 'none' }}>
                              {row.displayName}
                            </TableCell>
                            <TableCell>
                              {row.city}, {row.stateCode}
                            </TableCell>
                          </Box>
                        </TableRow>
                        <Box
                          style={{
                            marginTop:
                              expandedRow === row.id ? '120px' : '10px',
                          }}
                        >
                          <AddressBookActionMenu
                            address={row}
                            addEditAddress={addEditAddress}
                            deleteAddress={deleteAddress}
                          />
                        </Box>
                        <hr className="lineStyle" />
                      </Box>
                    ))}
                  </ShowUpXs>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
      <AddContactDialog
        open={openContatcDialog}
        onReject={handleContactClose}
        addEditAddress={addEditAddress}
      />
    </>
  );
};
