const hostUrl = 'https://rescloud.ediblearrangements.com/image/private';
const hostImageUrl = 'https://rescloud.ediblearrangements.com/image/upload/';
const hostUrlAuthenticated =
  'https://rescloud.ediblearrangements.com/image/authenticated/';
const creativeMarketingPath = 'Creative-Marketing/site_support';

export const carRedIcon = `${hostImageUrl}${creativeMarketingPath}/assets/car_red.webp`;
export const storeRedIcon = `${hostImageUrl}${creativeMarketingPath}/assets/store_red.webp`;
export const carWhiteIcon = `${hostImageUrl}${creativeMarketingPath}/assets/car_white.webp`;
export const storeWhiteIcon = `${hostImageUrl}${creativeMarketingPath}/assets/store_white.webp`;
export const calculatorIcon = `${hostImageUrl}${creativeMarketingPath}/assets/calculator_icon.webp`;
export const smoothieIconGreen = `${hostImageUrl}${creativeMarketingPath}/assets/smoothie_green.webp`;
export const headerEdibleLogo = `${hostImageUrl}${creativeMarketingPath}/assets/EA_NEW-VIS-ID_primary-logo_Horiz_200x60.webp`;
export const footerEdibleLogo = `${hostImageUrl}${creativeMarketingPath}/assets/EA_NEW-VIS-ID_primary-logo_Horiz_200x60.webp`;
export const notFoundPageEdibleLogo = `${hostImageUrl}${creativeMarketingPath}/assets/9.webp`;
export const noImageAvailableBanner = `${hostImageUrl}${creativeMarketingPath}/assets/no-image-available-banner.webp`;
export const noImageAvailableBannerDesktop = `${hostImageUrl}${creativeMarketingPath}/assets/no-image-available-banner-desktop.webp`;
export const menuBanner = `${hostImageUrl}/v1712168919/${creativeMarketingPath}/banners/hero/Web_NV-Under50-194x269.webp`;
export const ogImage = `${hostImageUrl}${creativeMarketingPath}/assets/og_image.webp`;
export const canadianFlag = `${hostImageUrl}${creativeMarketingPath}/assets/canadian_flag.webp`;
export const subscriptionDialogCloseButton = `${hostImageUrl}${creativeMarketingPath}/assets/subscription_dialog_close_button.webp`;
export const subscriptionDialogFormStep = `${hostImageUrl}${creativeMarketingPath}/assets/subscription_dialog_form_step.webp`;
export const subscriptionDialogSubmitSuccessStep = `${hostImageUrl}${creativeMarketingPath}/assets/subscription_dialog_submit_success_step.webp`;
export const dippedFruitBox = `${hostImageUrl}${creativeMarketingPath}/assets/dipped_fruit_box_png.webp`;
export const fruitBox = `${hostImageUrl}${creativeMarketingPath}/assets/fruit_box_png.webp`;
export const dessertsBox = `${hostImageUrl}v1715873873/${creativeMarketingPath}/CYO-Dessert-Box.webp`;
export const cookieBox = `${hostImageUrl}v1719850035/${creativeMarketingPath}/CYO-Cookie-Box.webp`;
export const noImageAvailable = `${hostImageUrl}${creativeMarketingPath}/assets/NoImageAvailable.webp`;
export const bouquetSmall = `${hostImageUrl}${creativeMarketingPath}/assets/bouquet_small.webp`;
export const pineappleFlower = `${hostImageUrl}${creativeMarketingPath}/assets/pineapple_flower.webp`;
export const paypal = `${hostImageUrl}${creativeMarketingPath}/assets/paypal.webp`;
export const store = `${hostImageUrl}${creativeMarketingPath}/assets/store_facade.webp`;
export const whileYouHere = `${hostImageUrl}${creativeMarketingPath}/assets/while_you_here.webp`;
export const whileYouHere2Lines = `${hostImageUrl}${creativeMarketingPath}/assets/while_you_here_2_lines.webp`;
export const handCraftedWithLoveBouquet = `${hostImageUrl}${creativeMarketingPath}/assets/hand_crafted_with_love_bouquet.webp`;
export const happyBirthdaySemiStrawberries = `${hostImageUrl}${creativeMarketingPath}/assets/happy_birthday_semi_strawberries.webp`;
export const momIsBerrySweetBouquet = `${hostImageUrl}${creativeMarketingPath}/assets/mom_is_berry_sweet_bouquet.webp`;
export const mothersDayIndulgenceBundle = `${hostImageUrl}${creativeMarketingPath}/assets/mothers_day_indulgence_bundle.webp`;
export const allergyInformationBanner = `${hostImageUrl}${creativeMarketingPath}/assets/allergy_Information_banner.webp`;
export const certifiedHappinessLogo = `${hostImageUrl}${creativeMarketingPath}/assets/Certified_Happiness_Logo.webp`;
export const EdibleGuaranteeLogo = `${hostImageUrl}${creativeMarketingPath}/2024_Edible_Guarantee_Logo_Edible_Guarantee_Logo-512x512.webp`;
export const EdibleGuaranteeSatisfaction = `${hostUrl}/s--QZySnZu4--/v1725911406/${creativeMarketingPath}/1_EdibleGuaranteeLP_satisfaction.jpg`;
export const EdibleGuaranteeStreamLined = `${hostUrl}/s--zhIp0nBP--/v1725911406/${creativeMarketingPath}/3_EdibleGuaranteeLP_streamlined.jpg`;
export const EdibleGuaranteeGuarantee = `${hostUrl}/s--CsjhAgql--/v1725911406/${creativeMarketingPath}/2_EdibleGuaranteeLP_guarantee.jpg`;
export const edibleRoundLogo = `${hostImageUrl}${creativeMarketingPath}/assets/edible_3x-8.webp`;
export const DeskPrintableCard1 = `${hostImageUrl}${creativeMarketingPath}/assets/thankYou_A_gallery_500x500_4_desktop.webp`;
export const DeskPrintableCard2 = `${hostImageUrl}${creativeMarketingPath}/assets/thankYou_A_gallery_500x500_5_desktop.webp`;
export const DeskPrintableCard3 = `${hostImageUrl}${creativeMarketingPath}/assets/thankYou_A_gallery_500x500_3_desktop.webp`;
export const MobiPrintableCard1 = `${hostImageUrl}${creativeMarketingPath}/assets/thankYou_A_gallery_500x500_4_mobile.webp`;
export const MobiPrintableCard2 = `${hostImageUrl}${creativeMarketingPath}/assets/thankYou_A_gallery_500x500_5_mobile.webp`;
export const MobiPrintableCard3 = `${hostImageUrl}${creativeMarketingPath}/assets/thankYou_A_gallery_500x500_3_mobile.webp`;
export const StrawberryImg = `${hostImageUrl}${creativeMarketingPath}/assets/strawberries_box.webp`;
export const StrawberryImgTab = `${hostImageUrl}${creativeMarketingPath}/assets/Strawberry_box_tabet.webp`;
export const StrawberryImgMob = `${hostImageUrl}${creativeMarketingPath}/assets/Strawberry_box_mobile.webp`;
export const ApplePayImg = `${hostImageUrl}${creativeMarketingPath}/assets/apple_pay_icon.webp`;
export const VenmoLogo = `${hostImageUrl}${creativeMarketingPath}/assets/venmo_icon.webp`;
export const PaypalLogo = `${hostImageUrl}${creativeMarketingPath}/assets/paypal_logo.webp`;
export const CheckImg = `${hostImageUrl}${creativeMarketingPath}/assets/help_icon.webp`;
export const CheckCircleImg = `${hostImageUrl}${creativeMarketingPath}/assets/check_circle.webp`;
export const PersonIcon = `${hostImageUrl}${creativeMarketingPath}/assets/person_icon.webp`;
export const TrackOrderImg = `${hostImageUrl}${creativeMarketingPath}/assets/track_icon.webp`;
export const subscriptionDialogDesktop = `${hostImageUrl}${creativeMarketingPath}/assets/l_Delicious_Fruit_Desgin_HD_Desktop.webp`;
export const subscriptionDialogTablet = `${hostImageUrl}${creativeMarketingPath}/assets/l_Delicious_Fruit_Desgin_HD_Tablet.webp`;
export const subscriptionDialogMobile = `${hostImageUrl}${creativeMarketingPath}/assets/l_Delicious_Fruit_Desgin_HD_Mobile.webp`;
export const HelpGreyIcon = `${hostImageUrl}${creativeMarketingPath}/assets/help_icon_grey.webp`;
export const shockedStrawberry = `${hostImageUrl}${creativeMarketingPath}/assets/surprised.webp`;
export const confusedStrawberry = `${hostImageUrl}${creativeMarketingPath}/assets/scare.webp`;
export const sadStrawberry = `${hostImageUrl}${creativeMarketingPath}/assets/sad.webp`;
export const addonArrow = `${hostImageUrl}${creativeMarketingPath}/assets/arrow-right-bottom.webp`;
export const triangleIcon = `${hostImageUrl}${creativeMarketingPath}/assets/Info_Icon_Triangle.webp`;
export const plusGreyIcon = `${hostImageUrl}${creativeMarketingPath}/assets/Add_Icon_grey.webp`;
export const strawberryBadge = `${hostImageUrl}${creativeMarketingPath}/assets/strawberry_icon_enabled.webp`;
export const disabledStrawberryBadge = `${hostImageUrl}${creativeMarketingPath}/assets/strawberry_icon_disabled.webp`;
export const pressRoomAdBadge01 = `${hostUrlAuthenticated}s---a6cYeEI--/v1726497866/${creativeMarketingPath}/assets/pr_bnr_enterpreneur.jpg `;
export const pressRoomAdBadge02 = `${hostUrlAuthenticated}s--tl456G1Q--/v1726497866/${creativeMarketingPath}/assets/pr_bnr_hot.jpg`;
export const unlockDiscountBaseImageDesktop = `${hostImageUrl}/v1705440454/${creativeMarketingPath}/New_User_Promo_-_Base_Background_Image_-_Desktop_and_Tablet.webp`;
export const unlockDiscountBaseImagePhone = `${hostImageUrl}/v1705440455/${creativeMarketingPath}/New_User_Promo_-_Base_Background_Image_-_Phone.webp`;
export const unlockDiscountSuccessImageDesktop = `${hostImageUrl}/v1705440458/${creativeMarketingPath}/New_User_Promo_-_Success_Background_Image_-_Desktop_and_Tablet.webp`;
export const unlockDiscountSuccessImagePhone = `${hostImageUrl}/v1705440459/${creativeMarketingPath}/New_User_Promo_-_Success_Background_Image_-_Phone.webp`;

export const unlockDiscountSignUpBaseImageDesktop = `${hostImageUrl}/v1708714023/${creativeMarketingPath}/New_User_Sign_Up_Banner_-_Desktop_-_Discount_Hidden.webp`;
export const unlockDiscountSignUpBaseImagePhone = `${hostImageUrl}/v1705440455/${creativeMarketingPath}/New_User_Sign_Up_Banner_-_Phone_-_Discount_Hidden.webp`;
export const unlockDiscountSignUpSuccessImageDesktop = `${hostImageUrl}/v1705440458/${creativeMarketingPath}/New_User_Sign_Up_Banner_-_Desktop_-_Discount_Revealed.webp`;
export const unlockDiscountSignUpSuccessImagePhone = `${hostImageUrl}/v1705440459/${creativeMarketingPath}/New_User_Sign_Up_Banner_-_Phone_-_Discount_Revealed.webp`;

export const printibleCards = `${hostUrl}/s--at4MHfrh--/v1683135948/${creativeMarketingPath}/Printible_cards_image.svg`;
export const printibleCardsDesktop = `${hostImageUrl}v1707157372/${creativeMarketingPath}/Printible_Promo-Desktop-Image_Column.webp`;
export const newPrintibleCardsDesktop = `${hostImageUrl}v1711572478/${creativeMarketingPath}/Printible-Promo_Edible-MDay-Cards---Desktop.webp`;
export const printibleCardsTablet = `${hostImageUrl}v1707157373/${creativeMarketingPath}/Printible_Promo-Tablet-Image_Column.webp`;
export const printibleCardsMobile = `${hostImageUrl}v1711572479/${creativeMarketingPath}/Printible-Promo_Edible-MDay-Cards---Phone.webp`;
export const complimentaryCard = `${hostUrl}/s--RksDAZvy--/v1660901261/${creativeMarketingPath}/complementary_card.svg`;
export const orderPlaced = `${hostUrl}/s--rZqf1qVl--/v1682017013/${creativeMarketingPath}/Order_Pending.svg`;
export const storeReceived = `${hostUrl}/s--ite7FdU---/v1682017014/${creativeMarketingPath}/Order_Recieved_By_Store.svg`;
export const craftingOrder = `${hostUrl}/s--ld0_qwNP--/v1682017014/${creativeMarketingPath}/Order_Creation.svg`;
export const outForDelivery = `${hostUrl}/s--mX2Bbs6k--/v1682017013/${creativeMarketingPath}/Order_Out_For_Delivery.svg`;
export const orderDelivered = `${hostUrl}/s--5jnp7h-M--/v1682017014/${creativeMarketingPath}/Order_Delivery_Successful.svg`;
export const deliveryUnsuccessful = `${hostUrl}/s--1yvWhfuh--/v1682017014/${creativeMarketingPath}/Order_Delivery_Unsuccessful.svg`;
export const orderReadyForPickup = `${hostUrl}/s--XIoVzuGR--/v1682017013/${creativeMarketingPath}/Order_Ready_For_Pickup.svg`;
export const orderPickedUp = `${hostUrl}/s--lPd6tN9W--/v1682017013/${creativeMarketingPath}/Order_Picked_Up.svg`;
export const orderShipped = `${hostUrl}/s--VKgD3kiI--/v1682017013/${creativeMarketingPath}/Order_Shipped.svg`;
export const orderDeliveredFedex = `${hostUrl}/s--FMcqyAzd--/v1682017014/${creativeMarketingPath}/Order_Delivered.svg`;
export const MasterLogo = `${hostUrl}/s--u1airau4--/v1655889103/${creativeMarketingPath}/mastercard_logo.svg`;
export const VisaLogo = `${hostUrl}/s--t3iCG3k8--/v1655889102/${creativeMarketingPath}/visa_logo.svg`;
export const AmericanLogo = `${hostUrl}/s--DgU5Q9dh--/v1655889102/${creativeMarketingPath}/american_express_logo.svg`;
export const DiscoveryLogo = `${hostUrl}/s--H_qyIf1n--/v1655889102/${creativeMarketingPath}/discover_card_logo.svg`;
export const GooglePayImg = `${hostUrl}/s--MVvU-uIm--/v1698875310/${creativeMarketingPath}/google-pay-logo.svg`;
export const LinkImg = `${hostUrl}/s--W2PU6up4--/v1698875310/${creativeMarketingPath}/link-social-logo.svg`;
export const AmazonPayImg = `${hostUrl}/s--tIZynQmV--/v1655889102/${creativeMarketingPath}/amazon_logo_wide.svg`;
export const storeWidgetSequenceBackground = `${hostImageUrl}v1697058037/${creativeMarketingPath}/Stores_Finder_Results_Pin_v2.svg`;
export const storeFinderMapPin = `${hostUrl}/s--zXZ18K7h--/v1689273153/${creativeMarketingPath}/Pin_-_with_dropshadow.svg`;
export const storeDetailsFranchiseBanner = `${hostUrl}/s--r1k5hTmR--/v1694598652/${creativeMarketingPath}/Store_Detail_Page_Banner1.webp`;
export const storeDetailsTopBannerDesktop = `${hostImageUrl}/v1722952900/${creativeMarketingPath}/banners/hero/Desktop-Banner-storedetails.webp`;
export const storeDetailsTopBannerTablet = `${hostImageUrl}/v1722952900/${creativeMarketingPath}/banners/hero/Tablet-2400x676-storedetails.webp`;
export const storeDetailsTopBannerMobile = `${hostImageUrl}/v1723644007/${creativeMarketingPath}/banners/hero/Mobile-1536x508-storedetails-image.webp`;
export const plusRedIcon = `${hostUrl}/s--Zyx5bIW5--/v1695056118/${creativeMarketingPath}/Add_red.svg`;
export const crossRedIcon = `${hostUrl}/s--ig_R56Cc--/v169505611/${creativeMarketingPath}/Clear_button_red.svg`;
export const closeIcon = `${hostUrl}/s--aAIOCvD8--/v1650912511/${creativeMarketingPath}/close_white.svg`;
export const payPalLogo = `${hostUrl}/s--TxQdda_M--/v1650912511/${creativeMarketingPath}/paypal_logo_new.svg`;
export const venmoLogo = `${hostUrl}/s--tMKoKtRn--/fl_sanitize/v1667210671/${creativeMarketingPath}/venmo.svg`;
export const amazonLogoWide = `${hostUrl}/s--tIZynQmV--/v1650912510/${creativeMarketingPath}/amazon_logo_wide.svg`;
export const mastercardLogo = `${hostUrl}/s--u1airau4--/v1650912510/${creativeMarketingPath}/mastercard_logo.svg`;
export const visaLogo = `${hostUrl}/s--t3iCG3k8--/v1650912510/${creativeMarketingPath}/visa_logo.svg`;
export const americanExpressLogo = `${hostUrl}/s--DgU5Q9dh--/v1650912510/${creativeMarketingPath}/american_express_logo.svg`;
export const discoverCardLogo = `${hostUrl}/s--H_qyIf1n--/v1650912510/${creativeMarketingPath}/discover_card_logo.svg`;
export const googlePlayLogo = `${hostUrl}/s--id1uq2Pf--/v1650912510/${creativeMarketingPath}/google_play_logo.svg`;
export const appStoreLogo = `${hostUrl}/s--U98Rx4US--/v1650912510/${creativeMarketingPath}/app_store_logo.svg`;
export const unitedStates = `${hostUrl}/s--aAFfaAS4--/v1655308711/${creativeMarketingPath}/united_states.svg`;
export const storeFinderFranchiseDesktop = `${hostImageUrl}/v1696272473/${creativeMarketingPath}/Franchise_Banner_-_Desktop_-_Cheesecakes.webp`;
export const storeFinderFranchiseTablet = `${hostImageUrl}/v1696272464/${creativeMarketingPath}/Franchise_Banner_-_Tablet_-_Cheesecakes.webp`;
export const storeFinderFranchiseMobile = `${hostImageUrl}/v1696272470/${creativeMarketingPath}/Franchise_Banner_-_Phone_-_Cheesecakes.webp`;
export const storeFinderMapMarkerPin = `${hostImageUrl}v1697058037/${creativeMarketingPath}/Stores_Finder_Map_Pin_v2.svg`;
export const storeFinderMapClusterPin = `${hostImageUrl}v1697058037/${creativeMarketingPath}/Stores_Finder_Map_Pin_Cluster_v2.svg`;
export const homeFranchiseDesktop = `${hostUrl}/s--LqO2-307--/v1713368752/${creativeMarketingPath}/Desktop-2120x640.webp`;
export const homeFranchiseTablet = `${hostUrl}/s--QIZyKwpf--/v1713368752/${creativeMarketingPath}/Tablet-2240x640.webp`;
export const homeFranchiseMobile = `${hostImageUrl}/v1713298284/${creativeMarketingPath}/Homepage_Franchise-Banner_Phone_16Apr2024.webp`;
export const oneHourDeliveryCar = `${hostUrl}/s--4fLi6o8d--/v1704820149/${creativeMarketingPath}/delivery.png`;
export const oneHourDeliveryChip = `${hostImageUrl}/v1712238245/${creativeMarketingPath}/1-hour-delivery_square-badge_2x.webp`;
export const oneHourDeliveryCartChip = `${hostImageUrl}v1718221049/${creativeMarketingPath}/1-hour-delivery_shopping-cart-edit-product-modal_badge_2x.webp`;
export const animatedLogo = `${hostImageUrl}/v1706285150/${creativeMarketingPath}/animated-basket_trans.webp`;
export const storeDesktop = `${hostUrl}/s--cxz0D3ZF--/v1709135616/${creativeMarketingPath}/assets/2024_Maintenance_Page_Image_459x393_Desktop.jpg`;
export const storeMobile = `${hostUrl}/s--D0u-eLuL--/v1709135616/${creativeMarketingPath}/assets/2024_Maintenance_Page_Image_390x268_Mobile.jpg`;
export const giftFinderInlineDesktop = `${hostImageUrl}/v1721242405/${creativeMarketingPath}/GiftFinder2Banner_Desktop.webp`;
export const giftFinderInlineTablet = `${hostImageUrl}/v1721242405/${creativeMarketingPath}/GiftFinder2Banner_Tablet.webp`;
export const giftFinderInlineMobile = `${hostImageUrl}/v1721242405/${creativeMarketingPath}/GiftFinder2Banner_Phone.webp`;
export const footerCookieSettings = `${hostUrl}/s--Tf8sFcrL--/v1709672207/${creativeMarketingPath}/privacyoptions730x350_1.png`;
export const storeBanner = `${hostUrl}/s--17j8xCv2--/v1709846112/${creativeMarketingPath}/2024_Maintenance_Page_Image_1920x300_1.jpg`;
export const dessertsBoard = `${hostUrl}/s--1yjEuGsw--/v1715701983/${creativeMarketingPath}/CYO_Dessert_Board_-_Product_Image_-_DesktopAndTablet.svg`;

export const freeMessageLogo = `${hostUrl}/s--Ctim12Ct--/v1715449709/${creativeMarketingPath}/local-shipping.png`;
export const orderTrackingVanPin = `${hostImageUrl}t_Map Resize/v1715544988/${creativeMarketingPath}/Stores_Finder_Map_Pin_Cluster_v2_copy.webp`;
export const orderTrackingHousePin = `${hostImageUrl}t_Resize/v1715543258/${creativeMarketingPath}/house.webp`;
export const oneHourDeliveryBackground = `${hostImageUrl}v1717770474/${creativeMarketingPath}/One-Hour-Delivery_Chocolate-Dipped-Indulgence-Platter.webp`;
export const rewardsTopBannerDesktop = `${hostImageUrl}v1722533011/${creativeMarketingPath}/Edible_Rewards_Banner_-_Desktop.webp`;
export const rewardsTopBannerTablet = `${hostImageUrl}v1722533015/${creativeMarketingPath}/Edible_Rewards_Banner_-_Tablet.webp`;
export const rewardsTopBannerPhone = `${hostImageUrl}v1722533012/${creativeMarketingPath}/Edible_Rewards_Banner_-_Phone.webp`;
export const rewardsBottomBannerDesktop = `${hostImageUrl}v1722533913/${creativeMarketingPath}/One_Line_Skinny_Banner_-_Desktop_-_One-hour_Delivery.webp`;
export const rewardsBottomBannerTablet = `${hostImageUrl}v1722533918/${creativeMarketingPath}/One_Line_Skinny_Banner_-_Tablet_-_One-hour_Delivery.webp`;
export const rewardsBottomBannerPhone = `${hostImageUrl}v1722533916/${creativeMarketingPath}/One_Line_Skinny_Banner_-_Phone_-_One-hour_Delivery.webp`;
export const pressRoomKit = `${hostUrlAuthenticated}s--_931q-7V--/v1726496110/${creativeMarketingPath}/edible_press_kit_2024_2025.pdf`;
export const poweredByGoodyBadge = `${hostImageUrl}v1724430280/${creativeMarketingPath}/PoweredByGoody-Badge.svg`;

export const whoWeAreFreshDaily = `${hostImageUrl}v1726688342/${creativeMarketingPath}/bnr_Made_Fresh_Daily.webp`;
export const whoWeAreArtOfWow = `${hostImageUrl}v1726688342/${creativeMarketingPath}/bnr_The_Art_Of_Wow.webp`;
export const whoWeAreGoLocal = `${hostImageUrl}v1726688342/${creativeMarketingPath}/bnr_Go_Local.webp`;
export const whoWeAreSpecialDelivery = `${hostImageUrl}v1726688342/${creativeMarketingPath}/bnr_Extra_Special.webp`;
export const whoWeAreFreshDailyMobile = `${hostImageUrl}v1726688249/${creativeMarketingPath}/os_made-fresh-daily.webp`;
export const whoWeAreArtOfWowMobile = `${hostImageUrl}v1726688249/${creativeMarketingPath}/os_the_art_of_wow.webp`;
export const whoWeAreGoLocalMobile = `${hostImageUrl}v1726688249/${creativeMarketingPath}/os_go_local_withus.webp`;
export const whoWeAreSpecialDeliveryMobile = `${hostImageUrl}v1726688249/${creativeMarketingPath}/os_extra_special_delivery.webp`;
export const careerMainBanner = `${hostImageUrl}v1727050084/${creativeMarketingPath}/MainBanner.webp`;
export const careerFlowerImage = `${hostImageUrl}v1727050084/${creativeMarketingPath}/img_arrangement.webp`;
export const careerCorporateImage = `${hostImageUrl}v1727050075/${creativeMarketingPath}/job_bg_corporate.webp`;
export const careerStoreImage = `${hostImageUrl}v1727050075/${creativeMarketingPath}/job_bg_store.webp`;
export const careerJobReviewsFirst = `${hostImageUrl}v1727050075/${creativeMarketingPath}/sara_ramones.png.webp`;
export const careerJobReviewsSecond = `${hostImageUrl}v1727050075/${creativeMarketingPath}/isgrey_brandt.png.webp`;
export const careerLaborSection = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_bg.webp`;
export const careerLaborSectionHover = `${hostImageUrl}v1727097631/${creativeMarketingPath}/icn_bg_hover.webp`;
export const careerLaborSectionImg1 = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_1.webp`;
export const careerLaborSectionImg2 = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_2.webp`;
export const careerLaborSectionImg3 = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_3.webp`;
export const careerLaborSectionImg4 = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_4.webp`;
export const careerLaborSectionImg5 = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_5.webp`;
export const careerLaborSectionImg6 = `${hostImageUrl}v1727050084/${creativeMarketingPath}/icn_6.webp`;
export const careerPropertiesPromise = `${hostImageUrl}v1727050076/${creativeMarketingPath}/Promise.webp`;
export const careerPropertiesPeople = `${hostImageUrl}v1727050076/${creativeMarketingPath}/People.webp`;
export const careerPropertiesProducts = `${hostImageUrl}v1727050076/${creativeMarketingPath}/Products.webp`;
export const careerPropertiesPlaces = `${hostImageUrl}v1727050075/${creativeMarketingPath}/Places.webp`;
export const careerPropertiesPurpose = `${hostImageUrl}v1727050076/${creativeMarketingPath}/Purpose.webp`;
