import * as TK from 'components/Rewards/translations/locale.constants';
import * as styles from 'components/Rewards/RewardLanding/styles';
import { AddReminderDialog } from 'components/Rewards/RewardLanding/MyRewards/Reminder/AddReminderDialog';
import { ArrangementGroupOccasion } from 'api/arrangement-group.api.service';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { ConfirmationDialog } from 'components/Rewards/RewardLanding/MyRewards/Reminder/ConfirmationDialog';
import { GiftRemindersResponse } from 'api/rewards/types/reminders-response.type';
import { Key } from 'enums/key';
import { ReminderParams } from 'components/Rewards/RewardLanding/MyRewards/Reminder/types';
import { useMenu } from 'hooks/useMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { FC, useEffect, useState } from 'react';
import i18next from 'i18next';

interface Props {
  giftReminderDetails: GiftRemindersResponse;
  addEditReminder: (data: ReminderParams) => void;
  deleteReminder: (reminderId: number) => void;
  occasionCategories: ArrangementGroupOccasion[];
}

export const ActionMenu: FC<Props> = ({
  giftReminderDetails,
  addEditReminder,
  deleteReminder,
  occasionCategories,
}) => {
  const { anchorEl, open, handleClick, handleClose } = useMenu();
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    document.body.style.overflow = 'scroll';
    setExpanded(open);
  }, [open]);
  const handleCloseAndUpdate = () => {
    handleClose();
  };

  const handleAccordionToggle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (anchorEl) {
      handleCloseAndUpdate();
    } else {
      handleClick(event);
    }
  };

  const [openConfirmationDialog, handleConfirmationDisplay] =
    useState<boolean>(false);
  const [openDialog, handleDisplay] = useState<boolean>(false);

  const openConfirmationRemoveDialog = () => {
    handleConfirmationDisplay(true);
    handleClose();
  };

  const handleConfirmationClosePopup = () => {
    handleConfirmationDisplay(false);
  };

  const openRemoveDialog = () => {
    handleDisplay(true);
    handleClose();
  };

  const handleClosePopup = () => {
    handleDisplay(false);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === Key.Tab) {
      e.stopPropagation();
    }
    if (e.key === Key.Escape) {
      handleClose();
    }
  };

  return (
    <>
      <Box mr={2}>
        <Button
          data-test="help-button"
          color="secondary"
          id="help-menu-button"
          aria-controls={open ? 'help-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleAccordionToggle}
          style={{ fontWeight: 600 }}
          endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          sx={styles.actionColor}
        >
          <Typography sx={styles.actionLink}>
            {i18next.t(TK.ACTION_TEXT)}
          </Typography>
        </Button>
        <Menu
          data-test="help-div"
          id="help-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'help-menu-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            component="a"
            href={`/${giftReminderDetails.url}`}
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            <Typography variant="body2">
              {' '}
              {i18next.t(TK.ACTION_ORDER)}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={openRemoveDialog}
            component="a"
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            <Typography variant="body2">
              {' '}
              {i18next.t(TK.ACTION_EDIT)}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={openConfirmationRemoveDialog}
            component="a"
            tabIndex={0}
            onKeyDown={onKeyDown}
          >
            <Typography variant="body2">
              {' '}
              {i18next.t(TK.ACTION_DELETE)}
            </Typography>
          </MenuItem>
        </Menu>
      </Box>
      <AddReminderDialog
        open={openDialog}
        onReject={handleClosePopup}
        giftReminderDetails={giftReminderDetails}
        addEditReminder={addEditReminder}
        occasionCategories={occasionCategories}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        onReject={handleConfirmationClosePopup}
        giftReminderDetails={giftReminderDetails}
        deleteReminder={deleteReminder}
      />
    </>
  );
};
